export const EXPO_LIST_REQUEST = 'EXPO_LIST_REQUEST';
export const EXPO_LIST_SUCCESS = 'EXPO_LIST_SUCCESS';
export const EXPO_LIST_FAIL = 'EXPO_LIST_FAIL';

export const EXPO_DETAILS_REQUEST = 'EXPO_DETAILS_REQUEST';
export const EXPO_DETAILS_SUCCESS = 'EXPO_DETAILS_SUCCESS';
export const EXPO_DETAILS_FAIL = 'EXPO_DETAILS_FAIL';

export const EXPO_DELETE_REQUEST = 'EXPO_DELETE_REQUEST';
export const EXPO_DELETE_SUCCESS = 'EXPO_DELETE_SUCCESS';
export const EXPO_DELETE_FAIL = 'EXPO_DELETE_FAIL';

export const EXPO_CREATE_REQUEST = 'EXPO_CREATE_REQUEST';
export const EXPO_CREATE_SUCCESS = 'EXPO_CREATE_SUCCESS';
export const EXPO_CREATE_FAIL = 'EXPO_CREATE_FAIL';
export const EXPO_CREATE_RESET = 'EXPO_CREATE_RESET';

export const EXPO_UPDATE_REQUEST = 'EXPO_UPDATE_REQUEST';
export const EXPO_UPDATE_SUCCESS = 'EXPO_UPDATE_SUCCESS';
export const EXPO_UPDATE_FAIL = 'EXPO_UPDATE_FAIL';
export const EXPO_UPDATE_RESET = 'EXPO_UPDATE_RESET';