export const PHOTO_LIST_REQUEST = 'PHOTO_LIST_REQUEST';
export const PHOTO_LIST_SUCCESS = 'PHOTO_LIST_SUCCESS';
export const PHOTO_LIST_FAIL = 'PHOTO_LIST_FAIL';

export const PHOTO_DETAILS_REQUEST = 'PHOTO_DETAILS_REQUEST';
export const PHOTO_DETAILS_SUCCESS = 'PHOTO_DETAILS_SUCCESS';
export const PHOTO_DETAILS_FAIL = 'PHOTO_DETAILS_FAIL';

export const PHOTO_DELETE_REQUEST = 'PHOTO_DELETE_REQUEST';
export const PHOTO_DELETE_SUCCESS = 'PHOTO_DELETE_SUCCESS';
export const PHOTO_DELETE_FAIL = 'PHOTO_DELETE_FAIL';

export const PHOTO_CREATE_REQUEST = 'PHOTO_CREATE_REQUEST';
export const PHOTO_CREATE_SUCCESS = 'PHOTO_CREATE_SUCCESS';
export const PHOTO_CREATE_FAIL = 'PHOTO_CREATE_FAIL';
export const PHOTO_CREATE_RESET = 'PHOTO_CREATE_RESET';

export const PHOTO_UPDATE_REQUEST = 'PHOTO_UPDATE_REQUEST';
export const PHOTO_UPDATE_SUCCESS = 'PHOTO_UPDATE_SUCCESS';
export const PHOTO_UPDATE_FAIL = 'PHOTO_UPDATE_FAIL';
export const PHOTO_UPDATE_RESET = 'PHOTO_UPDATE_RESET';