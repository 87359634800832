const paysages = [
            {
            '_id': '2',
            'image' : '/images/banc2.jpg',
            'name': 'Image 2',
            'price' : 'something'
            },

            
            {
            '_id': '3',
            'image' : '/images/paysageCouleur.jpg',
            'name': 'Image 3',
            'price' : 'something'
            },

            
            {
            '_id': '4',
            'image' : '/images/paysageNoir.jpg',
            'name': 'Image 4',
            'price' : 'something'
            },

            {
            "_id": "5",
            "image" : "/images/arbres.jpg",
            "name" : "Image 5",
            'price' : 'something'
            },
    
            
            {"_id": "6",
            "image" : "/images/locaux.jpg",
            "name" : "Image 5",
            'price' : 'something'
            },
    
            
            {"_id": "7",
            "image" : "/images/maison.jpg",
            "name" : "Image 5",
            'price' : 'something'
            },
    
            
            {"_id": "8",
            "image" : "/images/mer.jpg",
            "name" : "Image 5",
            'price' : 'something'
            },
    
            
            {"_id": "9",
            "image" : "/images/oiseau.jpg",
            "name" : "Image 5",
            'price' : 'something'
            },
    
            
            {"_id": "10",
            "image" : "/images/quai.jpg",
            "name" : "Image 5",
            'price' : 'something'
            },
    
]

export default paysages;