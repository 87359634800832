const villes = [
    {
    "_id": "12",
    "image" : "/images/rue.jpg",
    "name" : "Image 5",
    'price' : 'something'
    },
        
]

export default villes;